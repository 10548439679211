import React, { Fragment, useEffect, useState } from 'react';
import config from '../../config/config';
import AppBackground from './AppBackground';
import Footer from './Footer';

const Countdown = ({ children }) => {
    const [endTime, setEndTime] = useState(config.launchDate);

    const getTimeRemaining = () => {
        const total = Date.parse(new Date(endTime)) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
        const days = Math.floor(total / (1000 * 60 * 60 * 24));

        return {
            total,
            days,
            hours,
            minutes,
            seconds,
        };
    };

    const [time, setTime] = useState(getTimeRemaining());
    const [clicked, setClicked] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(getTimeRemaining());
        }, 1000);

        if (time.total < 0) {
            clearTimeout(timer);
        }

        return () => clearTimeout(timer);
    });

    const onClick = () => {
        if (clicked > 10) {
            setEndTime(new Date());
            return;
        }
        setClicked((prev) => prev + 1);
    };

    return (
        <Fragment>
            {time.total > 0 ? (
                <Fragment>
                    <main id='countdown'>
                        <AppBackground />
                        <h1 onClick={onClick}>
                            {config.appName}
                            <span>.</span>
                        </h1>
                        <h2>Launching Soon</h2>
                        <h3>Time left until launching</h3>
                        <div className='timer-container'>
                            <div className='time-container'>
                                <div className='time'>{time.days}</div>
                                <div className='label'>days</div>
                            </div>
                            <div className='time-container'>
                                <div className='time'>{time.hours}</div>
                                <div className='label'>hours</div>
                            </div>
                            <div className='time-container'>
                                <div className='time'>{time.minutes}</div>
                                <div className='label'>minutes</div>
                            </div>
                            <div className='time-container'>
                                <div className='time'>{time.seconds}</div>
                                <div className='label'>seconds</div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </Fragment>
            ) : (
                children
            )}
        </Fragment>
    );
};

export default Countdown;
