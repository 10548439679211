const FacebookIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8013 1.2 24 2.66667 24H12V14.6667H9.33333V11.3667H12V8.63333C12 5.748 13.616 3.72133 17.0213 3.72133L19.4253 3.724V7.19733H17.8293C16.504 7.19733 16 8.192 16 9.11467V11.368H19.424L18.6667 14.6667H16V24H21.3333C22.8 24 24 22.8013 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0Z'
            fill='white'
        />
    </svg>
);

export default FacebookIcon;
