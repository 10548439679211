import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CrossIcon from '../../assets/icons/CrossIcon';
import { MaxUint256 } from '@ethersproject/constants';
import config from '../../config/config';
import walletContext from '../../context/wallet/walletContext';
import Prompt from '../modals/Prompt';
import Balance from '../../liquidity/Balance';
import Rates from '../../liquidity/Rates';
import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { ETH, WBNB } from '../../config/constants';
import { calculateMargin } from '../../web3/utils';

const AddLiquidity = () => {
    const {
        selectedSymbol,
        insufficientBalance,
        token1Input,
        token2Input,
        resetTokenInputs,
        setModalMessage,
        openModal,
        closeModal,
    } = useContext(walletContext);

    const { chainId, library, account } = useWeb3React();

    const [isLoading, setIsLoading] = useState({
        token_1: false,
        token_2: false,
        supply: false,
    });
    const [approved, setApproved] = useState({
        token_1: false,
        token_2: false,
    });

    const [allowance, setAllowance] = useState({
        token_1: '',
        token_2: '',
    });

    const [modalOpen, setModalOpen] = useState({
        approveToken_1: false,
        approveToken_2: false,
        addLiquidity: false,
    });

    const liquidityButtons = [
        {
            label: `${isLoading.token_1 && !approved.token_1 ? 'Approving' : 'Approve'} ${
                chainId
                    ? selectedSymbol === ETH
                        ? WBNB
                        : config.tokensByNetwork[chainId]?.[0].symbol
                    : selectedSymbol
            }`,
            onClick: () => {
                setModalOpen((prev) => ({ ...prev, approveToken_1: true }));
            },
            disabled: !token1Input || approved.token_1,
        },
        {
            label: `${isLoading.token_2 && !approved.token_2 ? 'Approving' : 'Approve'} ${
                chainId ? config.tokensByNetwork[chainId]?.[1].symbol : config.appSymbol
            }`,
            onClick: () => {
                setModalOpen((prev) => ({ ...prev, approveToken_2: true }));
            },
            disabled: !token2Input || approved.token_2,
        },
        {
            label:
                isLoading.supply && approved.token_2 && approved.token_1
                    ? 'Supplying...'
                    : 'Supply',
            onClick: () => {
                setModalOpen((prev) => ({ ...prev, addLiquidity: true }));
            },
            disabled:
                !approved.token_1 || !approved.token_2 || (!token1Input && !token2Input),
        },
    ];

    const closeLiquidityModals = (modal) => {
        setModalOpen((prev) => ({ ...prev, [modal]: false }));
    };

    const approveToken1 = async () => {
        closeLiquidityModals('approveToken_1');

        const contract = new Contract(
            config.tokensByNetwork[chainId][0].address,
            config.erc20abi,
            library.getSigner()
        );

        try {
            setIsLoading((prev) => ({ ...prev, token_1: true }));

            let useExact = false;

            const estimatedGas = await contract.estimateGas
                .approve(config.pancakeswap.addresses.router, MaxUint256)
                .catch(() => {
                    // general fallback for tokens who restrict approval amounts

                    useExact = true;
                    return contract.estimateGas.approve(
                        config.pancakeswap.addresses.router,
                        parseUnits(token1Input.toString())
                    );
                });

            const approve = await contract.approve(
                config.pancakeswap.addresses.router,
                useExact ? parseUnits(token1Input.toString()) : MaxUint256,
                {
                    gasLimit: calculateMargin(estimatedGas),
                }
            );

            await approve.wait();

            setApproved((prev) => ({ ...prev, token_1: true }));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading((prev) => ({ ...prev, token_1: false }));
        }
    };

    const approveToken2 = async () => {
        closeLiquidityModals('approveToken_2');

        const contract = new Contract(
            config.tokensByNetwork[chainId][1].address,
            config.erc20abi,
            library.getSigner()
        );

        try {
            setIsLoading((prev) => ({ ...prev, token_2: true }));
            let useExact = false;
            const estimatedGas = await contract.estimateGas
                .approve(config.pancakeswap.addresses.router, MaxUint256)
                .catch(() => {
                    // general fallback for tokens who restrict approval amounts

                    useExact = true;
                    return contract.estimateGas.approve(
                        config.pancakeswap.addresses.router,
                        parseUnits(token2Input.toString())
                    );
                });

            const approve = await contract.approve(
                config.pancakeswap.addresses.router,
                useExact ? parseUnits(token2Input.toString()) : MaxUint256,
                {
                    gasLimit: calculateMargin(estimatedGas),
                }
            );

            await approve.wait();

            setApproved((prev) => ({ ...prev, token_2: true }));
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading((prev) => ({ ...prev, token_2: false }));
        }
    };

    const addLiquidity = async () => {
        closeLiquidityModals('addLiquidity');
        openModal('confirmation');

        const contract = new Contract(
            config.pancakeswap.addresses.router,
            config.pancakeswap.abis.router,
            library.getSigner()
        );

        try {
            setIsLoading((prev) => ({ ...prev, supply: true }));

            if (selectedSymbol === ETH) {
                const amountETHMin = token1Input - token1Input * config.slippage;

                const amountTokenMin = token2Input - token2Input * config.slippage;

                const liquidityETH = await contract.addLiquidityETH(
                    config.tokensByNetwork[chainId][1].address,
                    parseUnits(Number(token2Input).toFixed(10).toString()),
                    parseUnits(Number(amountTokenMin).toFixed(10).toString()),
                    parseUnits(Number(amountETHMin).toFixed(10).toString()),
                    account,
                    Date.now() + 15 * 60000,
                    {
                        value: parseUnits(Number(token1Input).toFixed(18).toString()),
                    }
                );

                closeModal('confirmation');
                setModalMessage(liquidityETH.hash);
                openModal('transactionSuccess');

                await liquidityETH.wait();

                resetTokenInputs();
                return;
            }

            const amountAMin = token1Input - token1Input * config.slippage;
            const amountBMin = token2Input - token2Input * config.slippage;

            const liquidity = await contract.addLiquidity(
                config.tokensByNetwork[chainId][0].address,
                config.tokensByNetwork[chainId][1].address,
                parseUnits(
                    Number(token1Input)
                        .toFixed(config.tokensByNetwork[chainId][0].decimals)
                        .toString(),
                    config.tokensByNetwork[chainId][0].decimals
                ),
                parseUnits(
                    Number(token2Input)
                        .toFixed(config.tokensByNetwork[chainId][1].decimals)
                        .toString(),
                    config.tokensByNetwork[chainId][1].decimals
                ),
                parseUnits(
                    Number(amountAMin)
                        .toFixed(config.tokensByNetwork[chainId][0].decimals)
                        .toString(),
                    config.tokensByNetwork[chainId][0].decimals
                ),
                parseUnits(
                    Number(amountBMin)
                        .toFixed(config.tokensByNetwork[chainId][1].decimals)
                        .toString(),
                    config.tokensByNetwork[chainId][1].decimals
                ),
                account,
                Date.now() + 15 * 60000
            );

            closeModal('confirmation');
            setModalMessage(liquidity.hash);
            openModal('transactionSuccess');

            await liquidity.wait();

            resetTokenInputs();
        } catch (error) {
            console.log(error);
        } finally {
            closeModal('confirmation');
            setIsLoading((prev) => ({ ...prev, supply: false }));
        }
    };

    useEffect(() => {
        if (!library || !account) return;

        (async () => {
            const token1Contract = new Contract(
                config.tokensByNetwork[chainId][0].address,
                config.erc20abi,
                library.getSigner()
            );

            const token2Contract = new Contract(
                config.tokensByNetwork[chainId][1].address,
                config.erc20abi,
                library.getSigner()
            );

            const token1Allowance = await token1Contract.allowance(
                account,
                config.pancakeswap.addresses.router
            );

            const token2Allowance = await token2Contract.allowance(
                account,
                config.pancakeswap.addresses.router
            );

            setAllowance({
                token_1: parseFloat(formatUnits(token1Allowance.toString(), 18)),
                token_2: parseFloat(formatUnits(token2Allowance.toString(), 18)),
            });
        })();

        return () => resetTokenInputs();
        // eslint-disable-next-line
    }, [library, account]);

    useEffect(() => {
        if (
            selectedSymbol !== ETH &&
            token1Input &&
            parseFloat(token1Input) > allowance.token_1
        ) {
            setApproved((prev) => ({ ...prev, token_1: false }));
            return;
        }

        if (token2Input && parseFloat(token2Input) > allowance.token_2) {
            setApproved((prev) => ({ ...prev, token_2: false }));
            return;
        }

        if (selectedSymbol === ETH || allowance.token_1 > 0) {
            setApproved((prev) => ({ ...prev, token_1: true }));
        }

        if (allowance.token_2 > 0) {
            setApproved((prev) => ({ ...prev, token_2: true }));
        }

        // eslint-disable-next-line
    }, [allowance, token1Input, token2Input]);

    return (
        <section id='liquidity'>
            <div className='container'>
                <div className='liquidity-form'>
                    <div className='header'>
                        <h2>Add Liquidity</h2>
                        <Link to='/'>
                            <CrossIcon />
                        </Link>
                    </div>
                    <div className='content'>
                        <Balance />
                        <Rates />
                        <div className='liquidity-buttons-container'>
                            {account ? (
                                token1Input && token2Input ? (
                                    !insufficientBalance ? (
                                        liquidityButtons
                                            .slice(selectedSymbol === ETH ? 1 : 0, 3)
                                            .map(
                                                ({ label, onClick, disabled }, index) => (
                                                    <div
                                                        key={label}
                                                        className='liquidity-button-row'
                                                    >
                                                        <div className='step'>
                                                            {index + 1}
                                                        </div>
                                                        <button
                                                            type='button'
                                                            onClick={onClick}
                                                            disabled={disabled}
                                                        >
                                                            {label}
                                                        </button>
                                                    </div>
                                                )
                                            )
                                    ) : (
                                        <div className='liquidity-button-row single'>
                                            <button disabled>Insufficient Balance</button>
                                        </div>
                                    )
                                ) : (
                                    <div className='liquidity-button-row single'>
                                        <button disabled>Enter an amount</button>
                                    </div>
                                )
                            ) : (
                                <div className='liquidity-button-row single'>
                                    <button disabled>Connect Wallet</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Prompt
                ariaHideApp={false}
                isOpen={modalOpen.approveToken_1}
                title={`By approving you give the Pancakeswap contract the ability to add liquidity with your tokens. This is due to the BSC token standard's requirement to approve tokens before transferring them to add liquidity.`}
                onRequestClose={() => closeLiquidityModals('approveToken_1')}
                onConfirm={approveToken1}
            />
            <Prompt
                ariaHideApp={false}
                isOpen={modalOpen.approveToken_2}
                title={`By approving you give the Pancakeswap contract the ability to add liquidity with your tokens. This is due to the BSC token standard's requirement to approve tokens before transferring them to add liquidity.`}
                onRequestClose={() => closeLiquidityModals('approveToken_2')}
                onConfirm={approveToken2}
            />
            <Prompt
                ariaHideApp={false}
                isOpen={modalOpen.addLiquidity}
                title='Are you sure you want to add liquidity?'
                onRequestClose={() => closeLiquidityModals('addLiquidity')}
                onConfirm={addLiquidity}
            />
        </section>
    );
};

export default AddLiquidity;
