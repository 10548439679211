const BnbIcon = () => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M9.67906 18.6993L15.8262 24.8465L21.9763 18.6967L25.553 22.2734L15.8262 32L6.10259 22.2763L9.67919 18.6996L9.67906 18.6993ZM0 16.1737L3.57685 19.7511L7.15345 16.1745L3.5766 12.5976L0 16.1737ZM9.67906 13.6477L15.8262 7.50092L21.9762 13.6506L25.5548 10.0758L25.553 10.0739L15.8262 0.347435L6.10259 10.0711L6.09753 10.0762L9.67944 13.6481L9.67906 13.6477ZM24.499 16.1722L28.0758 19.7491L31.6524 16.1725L28.0757 12.5956L24.499 16.1722Z'
            fill='white'
        />
        <path
            d='M19.4537 16.1756H19.4553L15.8261 19.8051L13.1436 17.1233L12.8355 16.815L12.1999 16.1793L12.1948 16.1744L12.1999 16.1692L15.8261 12.5424L19.4555 16.1718L19.4573 16.1739L19.454 16.1756'
            fill='white'
        />
    </svg>
);

export default BnbIcon;
