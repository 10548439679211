const TwitterIcon = () => (
    <svg
        width='30'
        height='24'
        viewBox='0 0 30 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M26.2968 6.8989C26.2968 6.64578 26.2936 6.38632 26.2809 6.12687C27.4485 5.28524 28.4641 4.22845 29.2646 3.02928C28.192 3.50705 27.0403 3.82662 25.8285 3.97533C27.0656 3.23178 28.0117 2.05792 28.4578 0.66258C27.3029 1.34918 26.0247 1.84276 24.661 2.11487C23.5694 0.950506 22.0127 0.222778 20.2946 0.222778C16.9882 0.222778 14.3114 2.90588 14.3114 6.20597C14.3114 6.67741 14.362 7.13304 14.4664 7.57284C9.49257 7.31655 5.08823 4.93719 2.13618 1.31754C1.62044 2.2003 1.32619 3.23178 1.32619 4.32653C1.32619 6.40531 2.37981 8.23412 3.98714 9.30989C3.00629 9.27825 2.08239 9.00615 1.27556 8.56002V8.63279C1.27556 11.531 3.33851 13.9484 6.07541 14.4989C5.57549 14.635 5.04393 14.7077 4.49972 14.7077C4.1137 14.7077 3.74035 14.6698 3.37648 14.6033C4.13902 16.9764 6.34752 18.7039 8.96417 18.7545C6.91704 20.3619 4.33835 21.3142 1.53501 21.3142C1.05092 21.3142 0.573146 21.2921 0.108032 21.2351C2.75317 22.9374 5.89822 23.9246 9.27741 23.9246C20.2819 23.9246 26.2968 14.809 26.2968 6.8989Z'
            fill='#F2FE72'
        />
    </svg>
);

export default TwitterIcon;
