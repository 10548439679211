import { isAddress } from '@ethersproject/address';
import { BigNumber } from '@ethersproject/bignumber';
import { Contract } from '@ethersproject/contracts';

export const fetcher =
    (library, abi) =>
    (...args) => {
        const [arg1, arg2, ...params] = args;

        if (isAddress(arg1)) {
            const address = arg1;
            const method = arg2;

            const contract = new Contract(address, abi, library);
            return contract[method](...params);
        }

        const method = arg1;

        return library[method](arg2, ...params);
    };

export const formatNumber = (num) =>
    num % 1 === 0 ? Number(num).toPrecision(10) : Number(num).toFixed(6);

export const calculateMargin = (value) =>
    value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));

export const getChainName = (chainId) => {
    switch (chainId) {
        case 1:
            return 'mainnet';
        case 3:
            return 'ropsten';
        case 4:
            return 'rinkeby';
        case 5:
            return 'goerli';
        case 42:
            return 'kovan';
        case 56:
            return 'bsc';
        case 97:
            return 'testnet';
        default:
            return '';
    }
};

export const formatDecimals = (num) => {
    const decimals = countDecimals(num);

    if (decimals > 4) {
        return (+num).toFixed(4);
    }

    return num;
};

const countDecimals = (num) => {
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes('.')) {
        return numStr.split('.')[1].length;
    }
    return 0;
};
