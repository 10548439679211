import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect } from 'react';
import useSWR from 'swr';
import config from '../config/config';
import walletContext from '../context/wallet/walletContext';
import { fetcher, formatDecimals } from '../web3/utils';
import TokenInput from './TokenInput';

const UsdcBalance = () => {
    const { selectedSymbol, token1Input, token1Balance, setTokenBalance, setTokenInput } =
        useContext(walletContext);

    const { account, library, chainId } = useWeb3React();

    const { data: balance } = useSWR(
        [chainId ? config.tokensByNetwork[chainId][0].address : '', 'balanceOf', account],
        {
            fetcher: fetcher(library, config.erc20abi),
        }
    );

    useEffect(() => {
        if (!chainId || (!balance && balance !== 0)) {
            return;
        }

        setTokenBalance({
            name: 'token1Balance',
            value: formatBalance(balance),
        });

        // eslint-disable-next-line
    }, [balance]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTokenInput({ name, value });
    };

    const formatBalance = (balance) =>
        formatUnits(balance, config.tokensByNetwork[chainId][0].decimals);

    const handleMax = () => {
        if (!account) return;
        setTokenInput({
            name: 'token1Input',
            value: formatBalance(balance),
        });
    };

    return (
        <TokenInput
            label={chainId ? config.tokensByNetwork[chainId][0].symbol : selectedSymbol}
            token={token1Input}
            balance={token1Balance ? formatDecimals(token1Balance) : 0}
            tokenName='token1Input'
            onChange={handleChange}
            onMax={handleMax}
        />
    );
};

export default UsdcBalance;
