import React, { useContext, useEffect, useState } from 'react';
import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import { BLOCKS_PER_DAY, customWETH, ETH } from '../../config/constants';
import { fetcher } from '../../web3/utils';
import useSWR from 'swr';
import config from '../../config/config';
import walletContext from '../../context/wallet/walletContext';
import { useRef } from 'react';

const Apy = () => {
    const { chainId, library } = useWeb3React();
    const isMounted = useRef(true);

    const { getPair, selectedSymbol, getTokenPriceUSD } = useContext(walletContext);

    const [apy, setApy] = useState(0);

    const poolId = selectedSymbol === ETH ? config.poolIds.pair1 : config.poolIds.pair2;

    const { data: pairAddress } = useSWR(
        [
            config.pancakeswap.addresses.factory,
            'getPair',

            selectedSymbol === ETH
                ? customWETH[chainId]?.address
                : config.tokensByNetwork[chainId]?.[0].address ?? '',
            config.tokensByNetwork[chainId]?.[1].address ?? '',
        ],
        {
            fetcher: fetcher(library, config.pancakeswap.abis.factory),
        }
    );

    const { data: poolInfo } = useSWR(
        [config.miningAddress[chainId], 'poolInfo', poolId],
        {
            fetcher: fetcher(library, config.miningAbi),
        }
    );

    const { data: totalSupply } = useSWR([pairAddress, 'totalSupply'], {
        fetcher: fetcher(library, config.pancakeswap.abis.pair),
    });

    const { data: lmBalance } = useSWR(
        [pairAddress, 'balanceOf', config.miningAddress[chainId]],
        {
            fetcher: fetcher(library, config.ersc20abi),
        }
    );

    useEffect(() => {
        setApy(0);
        isMounted.current = true;

        if (!chainId || !totalSupply || !lmBalance) {
            return;
        }

        (async () => {
            try {
                const pair = await getPair();

                // const etherPrice = await getTokenPriceUSD('ethereum');
                const bnbPrice = await getTokenPriceUSD('binancecoin');

                const tokenPrice = await getTokenPriceUSD(
                    config.tokensByNetwork[chainId]?.[0].coingeckoID
                );

                const token1_price = selectedSymbol === ETH ? bnbPrice : tokenPrice;

                const token2_price = await getTokenPriceUSD(
                    config.tokensByNetwork[chainId]?.[1].coingeckoID
                );

                // get the non-app token
                const token1Reserve =
                    pair.token0.symbol === customWETH[chainId].symbol ||
                    pair.token0.symbol === config.tokensByNetwork[chainId]?.[0].symbol
                        ? parseFloat(pair.reserve0.toExact())
                        : parseFloat(pair.reserve1.toExact());

                // get the app token
                const token2Reserve =
                    pair.token0.symbol === config.tokensByNetwork[chainId]?.[1].symbol
                        ? parseFloat(pair.reserve0.toExact())
                        : parseFloat(pair.reserve1.toExact());

                const rewardsPerBlock = parseFloat(
                    formatUnits(
                        poolInfo.tokenPerBlock,
                        config.tokensByNetwork[chainId]?.[1].decimals
                    )
                );

                const depositLPRatio = formatUnits(lmBalance) / formatUnits(totalSupply);

                const dpr =
                    (rewardsPerBlock * token2_price * BLOCKS_PER_DAY) /
                    (depositLPRatio * token1Reserve * token1_price +
                        depositLPRatio * token2Reserve * token2_price);

                const apy = dpr * 365 * 100;

                isMounted.current && setApy(apy);
            } catch (error) {
                console.log(error);
            }
        })();

        return () => (isMounted.current = false);

        // eslint-disable-next-line
    }, [chainId, totalSupply, lmBalance]);

    return (
        <div className='apy'>
            <p>APR</p>
            <span>{apy ? apy.toFixed(4) : '0.000'}%</span>
        </div>
    );
};

export default Apy;
