const TriangleSeven = () => (
    <svg
        width='141'
        height='186'
        viewBox='0 0 141 186'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='triangle-seven'
    >
        <path
            opacity='0.8'
            d='M156.735 106.477C167.088 100.488 167.088 85.5125 156.735 79.5228L23.7924 2.60863C13.4416 -3.37984 0.5 4.10471 0.5 16.0859V169.914C0.5 181.895 13.4417 189.38 23.7925 183.391L156.735 106.477Z'
            stroke='#D0CFCF'
        />
    </svg>
);

export default TriangleSeven;
