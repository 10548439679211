import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import config from '../../config/config';
import walletContext from '../../context/wallet/walletContext';

const Warning = (props) => {
    const { modal, closeModal } = useContext(walletContext);

    const onClose = () => {
        closeModal('warning');
    };

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.warning}
            overlayClassName='modal-overlay'
            className='modal-content warning'
            shouldCloseOnOverlayClick={true}
            onRequestClose={onClose}
        >
            <div className='content'>
                <p>{config.appName} only works on the Binance Smart Chain</p>
                <button type='button' onClick={onClose}>
                    Close
                </button>
            </div>
        </ReactModal>
    );
};

export default Warning;
