import React from 'react';
import TriangleNine from '../../assets/layout/TriangleNine';
import TriangleSeven from '../../assets/layout/TriangleSeven';
const AppBackground = () => (
    <div className='app-background'>
        <svg
            width='1388'
            height='966'
            viewBox='0 0 1388 966'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M83.5029 401.558C88.1657 398.865 88.1657 392.135 83.5029 389.442L25.4914 355.948C20.8286 353.255 15 356.621 15 362.005V428.995C15 434.379 20.8286 437.745 25.4914 435.052L83.5029 401.558Z'
                fill='url(#paint0_linear_1003:578)'
            />
            <path
                d='M207.609 880.848C214.797 876.693 214.797 866.307 207.609 862.152L118.174 810.462C110.986 806.308 102 811.501 102 819.81V923.19C102 931.499 110.986 936.692 118.174 932.538L207.609 880.848Z'
                fill='url(#paint1_linear_1003:578)'
            />
            <path
                d='M891.497 931.558C886.834 928.865 886.834 922.135 891.497 919.442L949.509 885.948C954.171 883.255 960 886.621 960 892.005V958.995C960 964.379 954.171 967.745 949.509 965.052L891.497 931.558Z'
                fill='url(#paint2_linear_1003:578)'
            />
            <path
                d='M1270.52 411.763C1273.83 409.868 1273.83 405.132 1270.52 403.237L1229.43 379.667C1226.13 377.772 1222 380.14 1222 383.93V431.07C1222 434.86 1226.13 437.228 1229.43 435.333L1270.52 411.763Z'
                fill='url(#paint3_linear_1003:578)'
            />
            {/* <path
                opacity='0.8'
                d='M1403.74 690.523L1270.79 613.609C1260.44 607.62 1247.5 615.105 1247.5 627.086V780.914C1247.5 792.895 1260.44 800.38 1270.79 794.391L1403.74 717.477C1414.09 711.488 1414.09 696.512 1403.74 690.523Z'
                stroke='#D0CFCF'
            /> */}
            <path
                opacity='0.8'
                d='M755.375 791.129L599.872 700.983C587.709 693.932 572.5 702.743 572.5 716.853V897.147C572.5 911.257 587.709 920.068 599.872 913.017L755.375 822.871C767.542 815.817 767.542 798.183 755.375 791.129Z'
                stroke='#D0CFCF'
            />
            <path
                opacity='0.8'
                d='M13.219 268.423L228.345 143.954C245.301 134.143 266.5 146.406 266.5 166.031V414.969C266.5 434.594 245.301 446.857 228.345 437.046L13.219 312.577C-3.73967 302.765 -3.73965 278.235 13.219 268.423Z'
                stroke='#D0CFCF'
            />
            {/* <path
                opacity='0.8'
                d='M1109.74 220.07L1399.79 52.1822C1422.77 38.8817 1451.5 55.506 1451.5 82.1122V417.888C1451.5 444.494 1422.77 461.118 1399.79 447.818L1109.74 279.93C1086.75 266.628 1086.75 233.372 1109.74 220.07Z'
                stroke='#D0CFCF'
            /> */}
            <path
                opacity='0.8'
                d='M479.07 20.397L608.79 -54.4496C618.884 -60.2735 631.5 -52.9932 631.5 -41.3466V108.347C631.5 119.993 618.884 127.274 608.79 121.45L479.07 46.603C468.977 40.7794 468.977 26.2206 479.07 20.397Z'
                stroke='#D0CFCF'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_1003:578'
                    x1='87'
                    y1='395.5'
                    x2='15'
                    y2='395.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_1003:578'
                    x1='213'
                    y1='871.5'
                    x2='102'
                    y2='871.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_1003:578'
                    x1='888'
                    y1='925.5'
                    x2='960'
                    y2='925.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint3_linear_1003:578'
                    x1='1273'
                    y1='407.5'
                    x2='1222'
                    y2='407.5'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
            </defs>
        </svg>
        <TriangleSeven />
        <TriangleNine />
    </div>
);

export default AppBackground;
