import React, { useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { customWETH, ETH } from '../config/constants';
import { fetcher } from '../web3/utils';
import { formatUnits } from '@ethersproject/units';
import config from '../config/config';
import walletContext from '../context/wallet/walletContext';
import useSWR from 'swr';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const LPTokens = () => {
    const { selectedSymbol } = useContext(walletContext);

    const { chainId, library, account } = useWeb3React();

    const { data: pairAddress } = useSWR(
        [
            config.pancakeswap.addresses.factory,
            'getPair',

            selectedSymbol === ETH
                ? customWETH[chainId]?.address
                : config.tokensByNetwork[chainId]?.[0].address ?? '',
            config.tokensByNetwork[chainId]?.[1].address ?? '',
        ],
        {
            fetcher: fetcher(library, config.pancakeswap.abis.factory),
        }
    );

    const { data: lpBalance } = useSWR([pairAddress ?? '', 'balanceOf', account], {
        fetcher: fetcher(library, config.erc20abi),
    });

    return (
        <div className='lp-tokens'>
            <p>Liquidity pool tokens</p>
            <span>
                {lpBalance
                    ? parseFloat(formatUnits(lpBalance, 18)).toPrecision(4)
                    : '0.000'}
            </span>
            {lpBalance > 0 && <Link to='/stake'>Stake</Link>}
        </div>
    );
};

export default LPTokens;
