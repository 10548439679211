const PancakeswapIcon = () => (
    <svg
        width='35'
        height='35'
        viewBox='0 0 35 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.04756 5.32137C6.51543 2.55426 8.64398 0 11.4111 0C13.8589 0 15.881 2.02212 15.881 4.46995V10.0042C16.5196 10.0042 17.1582 9.89775 17.7967 9.89775C18.4353 9.89775 18.9674 9.89775 19.606 10.0042V4.46995C19.606 2.02212 21.6281 0 24.076 0C26.8431 0 28.9716 2.55426 28.5459 5.32137L27.3752 11.8134C31.5259 13.6227 34.7187 16.8155 34.7187 20.7534V23.2012C34.7187 26.5004 32.5902 29.2675 29.5038 31.0768C26.4174 32.9925 22.3731 34.0568 17.7967 34.0568C13.2204 34.0568 9.17612 32.9925 6.08972 31.0768C3.10975 29.2675 0.981201 26.5004 0.981201 23.2012V20.7534C0.981201 16.8155 4.0676 13.6227 8.21827 11.8134L7.04756 5.32137ZM25.7788 12.6649L27.1624 5.00209C27.4816 3.0864 26.0981 1.27713 24.076 1.27713C22.3731 1.27713 20.9896 2.66069 20.9896 4.36352V11.3877C20.5639 11.2813 20.0317 11.2813 19.606 11.2813C18.9674 11.2813 18.4353 11.1749 17.7967 11.1749C17.1582 11.1749 16.5196 11.1749 15.881 11.2813C15.4553 11.2813 14.9232 11.3877 14.4975 11.3877V4.46995C14.4975 2.76711 13.1139 1.38356 11.4111 1.38356C9.4954 1.38356 8.00541 3.19282 8.43112 5.10852L9.81468 12.7713C5.34473 14.4741 2.36476 17.4541 2.36476 20.8598V23.3076C2.36476 28.5226 9.28254 32.7797 17.7967 32.7797C26.3109 32.7797 33.2287 28.5226 33.2287 23.3076V20.8598C33.3351 17.3477 30.3552 14.3677 25.7788 12.6649Z'
            fill='#C953C1'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.43113 5.10851C8.00542 3.19281 9.4954 1.38354 11.4111 1.38354C13.1139 1.38354 14.4975 2.7671 14.4975 4.46994V11.4942C15.5618 11.3877 16.626 11.2813 17.7967 11.2813C18.861 11.2813 19.9253 11.3877 20.9896 11.4942V4.46994C20.9896 2.7671 22.3731 1.38354 24.076 1.38354C25.9917 1.38354 27.4816 3.19281 27.1624 5.10851L25.7788 12.7713C30.2488 14.4741 33.3352 17.4541 33.3352 20.8598C33.3352 26.0747 26.4174 30.3318 17.9032 30.3318C9.38898 30.3318 2.47119 26.0747 2.47119 20.8598C2.47119 17.4541 5.45116 14.4741 9.92111 12.7713L8.43113 5.10851Z'
            fill='#C953C1'
            fillOpacity='0.5'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M2.47213 20.7534H2.47119V20.841C2.47129 20.8118 2.4716 20.7825 2.47213 20.7534ZM2.47119 20.8786V23.2012C2.47119 28.4161 9.38898 32.6732 17.9032 32.6732C26.4174 32.6732 33.3351 28.4161 33.3351 23.2012H33.4416V20.7534H33.3341C33.3348 20.7888 33.3351 20.8242 33.3351 20.8597C33.3351 26.0747 26.4173 30.3318 17.9031 30.3318C9.3992 30.3318 2.48783 26.0849 2.47119 20.8786Z'
            fill='#C953C1'
            fillOpacity='0.2'
        />
        <path
            d='M13.5397 20.1148C13.5397 21.4984 12.7947 22.6691 11.8369 22.6691C10.879 22.6691 10.134 21.4984 10.134 20.1148C10.134 18.7312 10.879 17.5605 11.8369 17.5605C12.7947 17.5605 13.5397 18.7312 13.5397 20.1148Z'
            fill='#C953C1'
        />
        <path
            d='M25.3529 20.1148C25.3529 21.4984 24.6079 22.6691 23.6501 22.6691C22.6923 22.6691 21.9473 21.4984 21.9473 20.1148C21.9473 18.7312 22.6923 17.5605 23.6501 17.5605C24.6079 17.5605 25.3529 18.7312 25.3529 20.1148Z'
            fill='#C953C1'
        />
    </svg>
);

export default PancakeswapIcon;
