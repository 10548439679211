import { Token } from '@pancakeswap/sdk';

export const networks = {
    MainNet: 1,
    Ropsten: 3,
    Rinkeby: 4,
    Goerli: 5,
    Kovan: 42,
    BscMainNet: 56,
    BscTestNet: 97,
};

export const ETH = 'ETH';
export const USDT = 'USDT';
export const USDC = 'USDC';
export const BNB = 'BNB';
export const BUSD = 'BUSD';
export const WBNB = 'WBNB';

export const BLOCKS_PER_DAY = 28143;

// because pancakeswap's WETH constant returns ETH on testnet
export const customWETH = {
    [networks.BscMainNet]: new Token(
        networks.BscMainNet,
        '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        18,
        'WBNB',
        'Wrapped BNB',
        'https://www.binance.org'
    ),
    [networks.BscTestNet]: new Token(
        networks.BscTestNet,
        '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
        18,
        'WBNB',
        'Wrapped BNB',
        'https://www.binance.org'
    ),
};
