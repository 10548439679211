const CoinMarketCapIcon = () => (
    <svg
        width='28'
        height='29'
        viewBox='0 0 28 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M24.0003 16.7396C23.7818 16.8901 23.5268 16.979 23.2621 16.9967C22.9974 17.0145 22.7328 16.9606 22.4962 16.8406C21.9408 16.5268 21.6414 15.791 21.6414 14.7883V11.7152C21.6414 10.24 21.0571 9.19037 20.0796 8.90543C18.4276 8.4221 17.1941 10.4456 16.718 11.2066L13.7964 15.9353V10.1642C13.7639 8.83329 13.3311 8.03617 12.5123 7.79811C11.9713 7.63941 11.1597 7.70433 10.3698 8.90903L3.83774 19.3943C2.96862 17.7315 2.51797 15.8818 2.52483 14.0056C2.52483 7.6863 7.57448 2.54647 13.7964 2.54647C20.0183 2.54647 25.086 7.6863 25.086 14.0056V14.038C25.086 14.038 25.086 14.0597 25.086 14.0705C25.1473 15.2932 24.7505 16.2671 24.0039 16.7396H24.0003ZM27.6072 14.0092V13.9479C27.5567 6.24714 21.3817 0 13.7964 0C6.21108 0 0 6.28321 0 14.0056C0 21.7279 6.18943 28.0148 13.7964 28.0148C17.2853 28.0146 20.6414 26.6773 23.1743 24.278C23.4202 24.0464 23.5648 23.7272 23.577 23.3896C23.5892 23.0521 23.4678 22.7233 23.2392 22.4746C23.1299 22.3536 22.9976 22.2554 22.8502 22.1857C22.7027 22.116 22.5429 22.0762 22.38 22.0685C22.2171 22.0608 22.0543 22.0854 21.9009 22.1408C21.7475 22.1963 21.6066 22.2815 21.4863 22.3916C20.3958 23.426 19.1075 24.2294 17.6988 24.7536C16.29 25.2777 14.7899 25.5118 13.2885 25.4417C11.787 25.3716 10.3152 24.9989 8.96143 24.3458C7.60763 23.6928 6.39977 22.7729 5.41034 21.6414L11.2896 12.1913V16.552C11.2896 18.6477 12.1011 19.3258 12.7828 19.5241C13.4645 19.7225 14.5069 19.5854 15.5998 17.8109L18.846 12.5556C18.947 12.3861 19.0444 12.2418 19.131 12.1156V14.7883C19.131 16.7468 19.9173 18.3122 21.2951 19.0841C21.9216 19.4223 22.6276 19.5857 23.339 19.5572C24.0504 19.5286 24.7411 19.3091 25.3384 18.9218C26.8533 17.9371 27.6793 16.1553 27.5928 14.0092H27.6072Z'
            fill='white'
        />
    </svg>
);

export default CoinMarketCapIcon;
