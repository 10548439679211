import React, { useReducer } from 'react';
import {
    RESET_TOKEN_BALANCE,
    RESET_TOKEN_INPUTS,
    SET_INSUFFICIENT_BALANCE,
    SET_MODAL_CLOSE,
    SET_MODAL_MESSAGE,
    SET_MODAL_OPEN,
    SET_PAIR,
    SET_PRICE,
    SET_SELECTED_SYMBOL,
    SET_TOKEN_BALANCE,
    SET_TOKEN_INPUT,
} from '../types';
import WalletContext from './walletContext';
import walletReducer from './walletReducer';
import { Pair, Route, Token, TokenAmount } from '@pancakeswap/sdk';
import { customWETH, ETH, USDC } from '../../config/constants';
import config from '../../config/config';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';

const WalletState = ({ children }) => {
    const initialState = {
        wallet: null,
        selectedSymbol: USDC,
        price: [0, 0],
        token1Balance: 0,
        token2Balance: 0,
        token1Input: '',
        token2Input: '',
        insufficientBalance: false,
        pair: {
            1: {
                weeklyRewards: '',
                shareOfPool: '',
            },
            2: {
                weeklyRewards: '',
                shareOfPool: '',
            },
        },
        modal: {
            confirmation: false,
            transactionSuccess: false,
            warning: false,
            message: '',
        },
    };

    const [state, dispatch] = useReducer(walletReducer, initialState);

    const {
        wallet,
        selectedSymbol,
        price,
        token1Balance,
        token2Balance,
        token1Input,
        token2Input,
        insufficientBalance,
        pair,
        modal,
    } = state;

    const { chainId, library } = useWeb3React();

    const getPair = async () => {
        try {
            const token_1 = new Token(
                chainId,
                config.tokensByNetwork[chainId][0].address,
                config.tokensByNetwork[chainId][0].decimals,
                config.tokensByNetwork[chainId][0].symbol,
                config.tokensByNetwork[chainId][0].name
            );

            const token_2 = new Token(
                chainId,
                config.tokensByNetwork[chainId][1].address,
                config.tokensByNetwork[chainId][1].decimals,
                config.tokensByNetwork[chainId][1].symbol,
                config.tokensByNetwork[chainId][1].name
            );

            const weth = customWETH[chainId];

            const currentPair = selectedSymbol === ETH ? weth : token_1;

            const factoryContract = new Contract(
                config.pancakeswap.addresses.factory,
                config.pancakeswap.abis.factory,
                library
            );

            const pairAddress = await factoryContract.getPair(
                currentPair.address,
                token_2.address
            );

            const pairContract = new Contract(
                pairAddress,
                config.pancakeswap.abis.pair,
                library
            );

            const [reserves0, reserves1] = await pairContract.getReserves();

            const balances = currentPair.sortsBefore(token_2)
                ? [reserves0, reserves1]
                : [reserves1, reserves0];

            // const pair = await Fetcher.fetchPairData(currentPair, token_2, library);
            const pair = new Pair(
                new TokenAmount(currentPair, balances[0]),
                new TokenAmount(token_2, balances[1])
            );

            return pair;
        } catch (error) {
            console.log(error);
        }
    };

    const getPrice = async () => {
        setPrice([0, 0]);

        try {
            const token_2 = new Token(
                chainId,
                config.tokensByNetwork[chainId][1].address,
                config.tokensByNetwork[chainId][1].decimals,
                config.tokensByNetwork[chainId][1].symbol,
                config.tokensByNetwork[chainId][1].name
            );

            const pair = await getPair();

            const route = new Route([pair], token_2);

            setPrice([
                route.midPrice.toSignificant(6),
                route.midPrice.invert().toSignificant(6),
            ]);
        } catch (error) {
            console.log(error);
        }
    };

    const getTokenPriceUSD = async (tokenName) => {
        try {
            const res = await fetch(
                `https://api.coingecko.com/api/v3/simple/price?ids=${tokenName}%2C&vs_currencies=usd`
            );

            const price = await res.json();

            return price[tokenName].usd;
        } catch (error) {
            console.log(error);
        }
    };

    const setSelectedSymbol = (symbol) => {
        dispatch({
            type: SET_SELECTED_SYMBOL,
            payload: symbol,
        });
    };

    const setPrice = (price) => {
        dispatch({
            type: SET_PRICE,
            payload: price,
        });
    };

    const setPair = (pairData) => {
        dispatch({
            type: SET_PAIR,
            payload: pairData,
        });
    };

    const setTokenInput = async ({ name, value }) => {
        dispatch({
            type: SET_TOKEN_INPUT,
            payload: {
                name,
                value: parseFloat(value),
            },
        });

        const pairInputValue =
            name === 'token1Input'
                ? parseFloat(value) * parseFloat(price[1])
                : parseFloat(value) * parseFloat(price[0]);

        dispatch({
            type: SET_TOKEN_INPUT,
            payload: {
                name: name === 'token1Input' ? 'token2Input' : 'token1Input',
                value: pairInputValue.toFixed(20).replace(/^0+(\d)|(\d)0+$/gm, '$1$2'),
            },
        });
    };

    const setTokenBalance = (token) => {
        dispatch({
            type: SET_TOKEN_BALANCE,
            payload: token,
        });
    };

    const setInsufficientBalance = (state) => {
        dispatch({
            type: SET_INSUFFICIENT_BALANCE,
            payload: state,
        });
    };

    const setModalMessage = (message) => {
        dispatch({
            type: SET_MODAL_MESSAGE,
            payload: message,
        });
    };

    const openModal = (modal) => {
        dispatch({
            type: SET_MODAL_OPEN,
            payload: modal,
        });
    };

    const closeModal = (modal) => {
        dispatch({
            type: SET_MODAL_CLOSE,
            payload: modal,
        });
    };

    const resetTokenInputs = () => {
        dispatch({
            type: RESET_TOKEN_INPUTS,
        });
    };

    const resetTokenBalance = () => {
        dispatch({
            type: RESET_TOKEN_BALANCE,
        });
    };

    return (
        <WalletContext.Provider
            value={{
                wallet,
                selectedSymbol,
                price,
                pair,
                token1Balance,
                token2Balance,
                token1Input,
                token2Input,
                insufficientBalance,
                modal,
                getTokenPriceUSD,
                getPair,
                getPrice,
                setSelectedSymbol,
                setPair,
                setTokenBalance,
                setTokenInput,
                setInsufficientBalance,
                setModalMessage,
                openModal,
                closeModal,
                resetTokenBalance,
                resetTokenInputs,
            }}
        >
            {children}
        </WalletContext.Provider>
    );
};

export default WalletState;
