const BusdIcon = () => (
    <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12.2489 28.0759L28.0751 12.249L31.6526 15.8248L15.8254 31.6526L12.2489 28.0759ZM0 15.8263L3.57667 19.4037L7.15309 15.8271L3.57642 12.2502L0 15.8263ZM9.67857 13.3003L19.4021 3.5766L15.8254 2.28882e-05L6.10228 9.72371L6.09722 9.72877L9.67857 13.3003Z'
            fill='white'
        />
        <path
            d='M6.27087 22.1027L22.1019 6.27163L25.6805 9.84837L9.84851 25.6804L6.27087 22.1027Z'
            fill='white'
        />
    </svg>
);

export default BusdIcon;
