import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect } from 'react';
import ReactModal from 'react-modal';
import CrossIcon from '../../assets/icons/CrossIcon';
import Uploaded from '../../assets/icons/Uploaded';
import { networks } from '../../config/constants';
import walletContext from '../../context/wallet/walletContext';
import { getChainName } from '../../web3/utils';
import ExternalLink from '../reusable/ExternalLink';

const TransactionSuccessful = (props) => {
    const { modal, closeModal, setModalMessage } = useContext(walletContext);

    const { chainId } = useWeb3React();

    const onClose = () => {
        closeModal('transactionSuccess');
    };

    useEffect(() => {
        return () => {
            setModalMessage('');
        };

        // eslint-disable-next-line
    }, []);

    return (
        <ReactModal
            {...props}
            ariaHideApp={false}
            isOpen={modal.transactionSuccess}
            overlayClassName='modal-overlay'
            className='modal-content transaction-success'
            shouldCloseOnOverlayClick={true}
            onRequestClose={onClose}
        >
            <div className='header'>
                <div className='cross-container' onClick={onClose}>
                    <CrossIcon />
                </div>
            </div>
            <div className='content'>
                <div className='uploaded-container'>
                    <Uploaded />
                </div>
                <p>Transaction Submitted</p>
                <ExternalLink
                    to={`https://${
                        chainId &&
                        (chainId === networks.MainNet || chainId === networks.BscMainNet)
                            ? ''
                            : `${getChainName(chainId)}.`
                    }bscscan.com/tx/${modal.message}`}
                >
                    View on BscScan
                </ExternalLink>
            </div>
        </ReactModal>
    );
};

export default TransactionSuccessful;
