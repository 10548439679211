import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { LedgerConnector } from '@web3-react/ledger-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { networks } from '../config/constants';

const rpcUrls = {
    [networks.MainNet]: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Ropsten]: `https://ropsten.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Rinkeby]: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Goerli]: `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.Kovan]: `https://kovan.infura.iov3/${process.env.REACT_APP_INFURA_ID}`,
    [networks.BscMainNet]: 'https://bsc-dataseed.binance.org/',
    [networks.BscTestNet]: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
};

const pollingInterval = 12000;

export const bscConnector = new BscConnector({
    supportedChainIds: [
        networks.BscMainNet,
        // networks.BscTestNet
    ],
});

export const injectedConnector = new InjectedConnector({
    supportedChainIds: [
        // networks.MainNet,
        // networks.Ropsten,
        // networks.Rinkeby,
        // networks.Goerli,
        // networks.Kovan,
        networks.BscMainNet,
        // networks.BscTestNet,
    ],
});

export const InfuraConnector = new NetworkConnector({
    urls: {
        // [networks.MainNet]: rpcUrls[networks.MainNet],
        // [networks.Ropsten]: rpcUrls[networks.Ropsten],
        // [networks.Rinkeby]: rpcUrls[networks.Rinkeby],
        // [networks.Goerli]: rpcUrls[Goerli],
        // [networks.Kovan]: rpcUrls[networks.Kovan],
        [networks.BscMainNet]: rpcUrls[networks.BscMainNet],
        // [networks.BscTestNet]: rpcUrls[networks.BscTestNet],
    },
    defaultChainId: networks.BscMainNet,
    pollingInterval,
});

export const WalletConnector = new WalletConnectConnector({
    rpc: {
        [networks.MainNet]: rpcUrls[networks.MainNet],
        // [networks.Ropsten]: rpcUrls[networks.Ropsten],
        // [networks.Rinkeby]: rpcUrls[networks.Rinkeby],
        // [networks.Goerli]: rpcUrls[networks.Goerli],
        // [networks.Kovan]: rpcUrls[networks.Kovan],
    },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval,
});

export const LedgerConnect = new LedgerConnector({
    chainId: networks.MainNet,
    url: rpcUrls[networks.MainNet],
    pollingInterval,
});
