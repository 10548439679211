import React, { useContext, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import walletContext from '../context/wallet/walletContext';
import SwapIcon from '../assets/icons/SwapIcon';
import config from '../config/config';
import { ETH, WBNB } from '../config/constants';

const Rates = () => {
    const { selectedSymbol, price, getPrice } = useContext(walletContext);

    const { chainId } = useWeb3React();

    const token_1Symbol = config.tokensByNetwork[chainId]?.[0].symbol ?? config.appSymbol;

    const token_2Symbol = config.tokensByNetwork[chainId]?.[1].symbol ?? config.appSymbol;

    useEffect(() => {
        if (!chainId) {
            return;
        }

        getPrice();

        // eslint-disable-next-line
    }, [chainId]);

    return (
        <div className='liquidity-container'>
            <div className='inner__left'>
                <p>
                    {selectedSymbol === ETH ? WBNB : token_1Symbol} per {token_2Symbol}
                </p>
                <span>{price?.[0]}</span>
            </div>
            <SwapIcon />
            <div className='inner__right'>
                <p>
                    {token_2Symbol} per {selectedSymbol === ETH ? WBNB : token_1Symbol}
                </p>
                <span>{price?.[1]}</span>
            </div>
        </div>
    );
};

export default Rates;
