const MediumIcon = () => (
    <svg
        width='28'
        height='21'
        viewBox='0 0 28 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M27.0648 3.23702H27.4336V0.284058H17.6677L14.2 13.0694H14.0869L10.6511 0.284058H0.855591V3.23702H1.22445C2.33217 3.23702 2.33217 4.34377 2.33217 4.34377V16.8947C2.33217 18.0022 1.22445 18.0022 1.22445 18.0022H0.855591V20.9553H8.23849V18.0022H6.76191V4.27058H6.90945L11.3933 20.9553H15.3783L19.9032 4.27058H20.0507V18.0022H18.5744V20.9553H27.4336V18.0022H27.0648C25.9571 18.0022 25.9571 16.8954 25.9571 16.8954V4.34474C25.9571 3.23702 27.0648 3.23702 27.0648 3.23702Z'
            fill='#F2FE72'
        />
    </svg>
);

export default MediumIcon;
