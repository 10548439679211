import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import UniswapIcon from '../../assets/icons/UniswapIcon';
import WalletIcon from '../../assets/icons/WalletIcon';
import ConnectWallet from '../modals/ConnectWallet';
import ExternalLink from '../reusable/ExternalLink';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { InfuraConnector } from '../../web3/connectors';
import { networks } from '../../config/constants';
import walletContext from '../../context/wallet/walletContext';
import Logo from '../../assets/logo/Logo';
import PancakeswapIcon from '../../assets/icons/Pancakeswapicon';
import config from '../../config/config';

const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [sticky, setSticky] = useState(false);

    const { chainId, account, error, activate, connector } = useWeb3React();

    const { openModal, closeModal } = useContext(walletContext);

    const promptNetworkSwitch = async () => {
        try {
            const provider = window.ethereum;

            if (!provider) return;

            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId: `0x${parseInt(networks.BscMainNet, 10).toString(16)}`,
                    },
                ],
            });
        } catch (error) {
            if (error?.code === 4902) {
                promptAddNetwork();
                return;
            }
            openModal('warning');
        }
    };

    const promptAddNetwork = async () => {
        try {
            const provider = window.ethereum;

            if (!provider) return;

            await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: `0x${parseInt(networks.BscMainNet, 10).toString(16)}`,
                        chainName: 'Binance Smart Chain Mainnet',
                        nativeCurrency: {
                            name: 'BNB',
                            symbol: 'BNB',
                            decimals: 18,
                        },
                        rpcUrls: ['https://bsc-dataseed1.binance.org'],
                        blockExplorerUrls: ['https://bscscan.com'],
                    },
                ],
            });
        } catch (error) {
            console.log(error);
            openModal('warning');
        }
    };

    useEffect(() => {
        activate(InfuraConnector);

        const scrollListener = () => {
            if (window.scrollY > 10) {
                setSticky(true);
                return;
            }
            setSticky(false);
        };

        window.addEventListener('scroll', scrollListener);

        return () => {
            window.removeEventListener('scroll', scrollListener);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (connector && error instanceof UnsupportedChainIdError) {
            promptNetworkSwitch();
        }

        closeModal('warning');

        // eslint-disable-next-line
    }, [connector, error]);

    useEffect(() => {
        if (chainId !== networks.BscMainNet) {
            openModal('warning');
        }

        // eslint-disable-next-line
    }, [chainId]);

    return (
        <header className={sticky ? 'sticky' : ''}>
            <div className='container'>
                <div className='inner__left'>
                    <Link to='/'>
                        <Logo />
                    </Link>
                </div>
                <div className='inner__right'>
                    <ExternalLink
                        to={`https://pancakeswap.finance/add/BNB/${config.tokensByNetwork[chainId]?.[1].address}`}
                        // to={`https://app.uniswap.org/#/add/v2/ETH/${config.tokensByNetwork[chainId]?.[1].address}`}
                        className='uniswap-container'
                    >
                        {/* <UniswapIcon /> */}
                        <PancakeswapIcon />
                    </ExternalLink>
                    <button
                        type='button'
                        className='wallet-container'
                        onClick={() => setOpen(true)}
                    >
                        <WalletIcon />
                        <span>
                            {account ? (
                                `${account.slice(0, 6)}...${account.slice(-4)}`
                            ) : (
                                <Fragment>
                                    Connect <span>Wallet</span>
                                </Fragment>
                            )}
                        </span>
                    </button>
                </div>
            </div>
            <ConnectWallet
                ariaHideApp={false}
                isOpen={open}
                onRequestClose={() => setOpen(false)}
            />
        </header>
    );
};

export default Navbar;
