import React, { useContext, useEffect } from 'react';
import config from '../config/config';
import walletContext from '../context/wallet/walletContext';
import TokenInput from './TokenInput';
import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import useSWR from 'swr';
import { fetcher, formatDecimals } from '../web3/utils';
import EthBalance from './EthBalance';
import UsdcBalance from './UsdcBalance';
import { ETH } from '../config/constants';

const Balance = () => {
    const {
        selectedSymbol,
        token1Balance,
        token1Input,
        token2Balance,
        token2Input,
        setTokenBalance,
        setTokenInput,
        setInsufficientBalance,
    } = useContext(walletContext);

    const { account, library, chainId } = useWeb3React();

    const { data: balance } = useSWR(
        [chainId ? config.tokensByNetwork[chainId][1].address : '', 'balanceOf', account],
        {
            fetcher: fetcher(library, config.erc20abi),
        }
    );

    useEffect(() => {
        if (!chainId || (!balance && balance !== 0)) {
            return;
        }

        setTokenBalance({
            name: 'token2Balance',
            value: formatBalance(balance),
        });

        // eslint-disable-next-line
    }, [balance]);

    useEffect(() => {
        if (
            Number(token2Input) > Number(token2Balance) ||
            Number(token1Input) > Number(token1Balance)
        ) {
            setInsufficientBalance(true);
            return;
        }

        setInsufficientBalance(false);

        // eslint-disable-next-line
    }, [token2Input, token1Input, token2Balance, token1Balance]);

    const formatBalance = (balance) =>
        formatUnits(balance, config.tokensByNetwork[chainId][1].decimals);

    const handleMax = () => {
        if (!account) return;
        setTokenInput({
            name: 'token2Input',
            value: parseFloat(formatBalance(balance)),
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTokenInput({ name, value });
    };

    return (
        <div className='amount-container'>
            {selectedSymbol === ETH ? <EthBalance /> : <UsdcBalance />}
            <TokenInput
                label={
                    chainId && chainId !== 1
                        ? config.tokensByNetwork[chainId][1].symbol
                        : config.appSymbol
                }
                token={token2Input}
                balance={token2Balance ? formatDecimals(token2Balance) : 0}
                tokenName='token2Input'
                onChange={handleChange}
                onMax={handleMax}
            />
        </div>
    );
};

export default Balance;
