import { formatEther } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { ETH, WBNB } from '../config/constants';
import walletContext from '../context/wallet/walletContext';
import { fetcher, formatDecimals } from '../web3/utils';
import TokenInput from './TokenInput';

const EthBalance = () => {
    const { selectedSymbol, token1Balance, token1Input, setTokenBalance, setTokenInput } =
        useContext(walletContext);

    const { chainId, account, library } = useWeb3React();

    const { data: balance } = useSWR(['getBalance', account, 'latest'], {
        fetcher: fetcher(library),
    });

    useEffect(() => {
        if (!chainId || (!balance && balance !== 0)) {
            return;
        }

        setTokenBalance({
            name: 'token1Balance',
            value: formatEth(balance),
        });
        // eslint-disable-next-line
    }, [balance]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTokenInput({ name, value });
    };

    const formatEth = (balance) => formatEther(balance.toString());

    const handleMax = () => {
        if (!account) return;
        setTokenInput({
            name: 'token1Input',
            value: formatEth(balance),
        });
    };

    return (
        <TokenInput
            label={selectedSymbol === ETH ? WBNB : selectedSymbol}
            token={token1Input}
            balance={token1Balance ? formatDecimals(token1Balance) : 0}
            tokenName='token1Input'
            onChange={handleChange}
            onMax={handleMax}
        />
    );
};

export default EthBalance;
