const TelegramIcon = () => (
    <svg
        width='31'
        height='25'
        viewBox='0 0 31 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M11.9188 16.0152L11.4163 22.7997C12.1352 22.7997 12.4466 22.5032 12.8199 22.1472L16.1904 19.0551L23.1742 23.9648C24.455 24.6501 25.3574 24.2892 25.7029 22.8337L30.2871 2.21298L30.2884 2.21176C30.6946 0.394148 29.6037 -0.316618 28.3557 0.12928L1.41015 10.0326C-0.428828 10.7179 -0.400985 11.702 1.09754 12.1479L7.98645 14.2049L23.988 4.59313C24.7411 4.11443 25.4258 4.3793 24.8626 4.858L11.9188 16.0152Z'
            fill='#F2FE72'
        />
    </svg>
);

export default TelegramIcon;
