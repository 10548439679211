import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CrossIcon from '../../assets/icons/CrossIcon';
import config from '../../config/config';
import { InfuraConnector } from '../../web3/connectors';

const ConnectWallet = (props) => {
    const [active, setActive] = useState();

    const { activate, deactivate } = useWeb3React();

    const connectWallet = (name, connector) => {
        if (active === name) {
            setActive('');
            deactivate(connector);
            return;
        }

        setActive(name);
        activate(connector);
        props.onRequestClose();
    };

    useEffect(() => {
        if (!active) {
            activate(InfuraConnector);
        }

        // eslint-disable-next-line
    }, [active]);

    return (
        <ReactModal
            {...props}
            overlayClassName='modal-overlay'
            className='modal-content connect-wallet'
            shouldCloseOnOverlayClick={true}
        >
            <div className='connect-wallet-container'>
                <div className='header'>
                    <h2>Wallet</h2>
                    <div className='cross-container' onClick={props.onRequestClose}>
                        <CrossIcon />
                    </div>
                </div>
                <div className='content'>
                    {config.walletConnections.map(({ name, icon, connector }) => (
                        <div
                            key={name}
                            className={`wallet-connector ${
                                active === name ? 'active' : ''
                            }`}
                            onClick={() => connectWallet(name, connector)}
                        >
                            {icon}
                            <h3>{name}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </ReactModal>
    );
};

export default ConnectWallet;
