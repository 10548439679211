const Logo = () => (
    <>
        <svg
            width='35'
            height='39'
            viewBox='0 0 35 39'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.1614 12.506H2.76136L2.37427 14.651H10.7872L11.1614 12.506Z'
                fill='url(#paint0_linear_1003:659)'
            />
            <path
                d='M25.6128 14.651H34.0773C34.1547 14.222 34.2321 13.806 34.2967 13.377H25.8321L25.6128 14.651Z'
                fill='url(#paint1_linear_1003:659)'
            />
            <path
                d='M25.3805 15.938C25.1612 16.601 24.4902 16.926 23.3676 16.926H12.2966C11.187 16.926 10.6192 16.562 10.6063 15.821H2.16761L1.78052 17.966H33.4708C33.5999 17.251 33.7289 16.536 33.8579 15.821H25.3934L25.3805 15.938Z'
                fill='url(#paint2_linear_1003:659)'
            />
            <path
                d='M0.309677 26.234H8.77419C8.77419 26.221 9.2129 23.686 9.2129 23.686C9.43226 23.023 10.1032 22.698 11.2258 22.698H22.2968C23.5613 22.698 24.1161 23.166 23.9613 24.102L22.9548 29.757C22.7355 30.42 22.0645 30.745 20.9419 30.745H9.88387C8.61936 30.745 8.06452 30.277 8.21936 29.341H0C0 31.915 0.774194 34.021 2.33548 35.659C4.14194 37.609 6.83871 38.727 10.4 39C10.4 39 13.729 39 17.4968 39C21.2645 39 24.4387 38.272 27.1742 36.166C29.4065 34.437 30.7226 32.24 31.2774 29.328C31.8323 26.416 33.3161 18.759 33.3161 18.759H1.63871L0.309677 26.234Z'
                fill='url(#paint3_linear_1003:659)'
            />
            <path
                d='M34.671 7.81305H4.16775C3.69033 8.51505 3.27742 9.73705 3.1613 10.283C3.04517 10.829 3.04517 10.933 3.04517 10.933H11.4323L11.6129 9.88005C11.8323 9.21705 12.5032 8.89205 13.6258 8.89205H24.6968C25.9613 8.89205 26.5032 9.36005 26.3613 10.296H34.8258C34.8645 9.41205 34.8 8.59305 34.671 7.81305Z'
                fill='url(#paint4_linear_1003:659)'
            />
            <path
                d='M22.6711 0H18.9163C15.9873 0 13.355 0.533 11.0195 1.56H29.9873C28.0389 0.533 25.6002 0 22.6711 0Z'
                fill='url(#paint5_linear_1003:659)'
            />
            <path
                d='M32.2064 3.133H8.24514C7.00643 4.017 5.96127 5.057 5.12256 6.266H34.2581C33.8193 5.044 33.1355 4.004 32.2064 3.133Z'
                fill='url(#paint6_linear_1003:659)'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_1003:659'
                    x1='6.76911'
                    y1='-0.0624137'
                    x2='6.76911'
                    y2='38.5974'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_1003:659'
                    x1='29.9492'
                    y1='-0.0623538'
                    x2='29.9492'
                    y2='38.5973'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_1003:659'
                    x1='17.8238'
                    y1='-0.0623999'
                    x2='17.8238'
                    y2='38.5973'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint3_linear_1003:659'
                    x1='16.6625'
                    y1='-0.0623645'
                    x2='16.6625'
                    y2='38.5974'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint4_linear_1003:659'
                    x1='18.9424'
                    y1='-0.0623361'
                    x2='18.9424'
                    y2='38.5974'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint5_linear_1003:659'
                    x1='20.5041'
                    y1='-0.062387'
                    x2='20.5041'
                    y2='38.5974'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint6_linear_1003:659'
                    x1='19.6861'
                    y1='-0.0623946'
                    x2='19.6861'
                    y2='38.5974'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
            </defs>
        </svg>
        <svg
            width='121'
            height='33'
            viewBox='0 0 121 33'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M24.8387 0.25H10.129C7.32903 0.25 4.94194 1.238 2.96774 3.227C0.993548 5.216 0 7.621 0 10.429V12.808V29.552C0 30.449 0.309679 31.203 0.929034 31.814C1.54839 32.438 2.29678 32.737 3.1871 32.737C4.07742 32.737 4.82581 32.425 5.44516 31.814C6.06452 31.19 6.37419 30.436 6.37419 29.552V20.582H19.4194C20.3097 20.582 21.0581 20.27 21.6903 19.633C22.3226 18.996 22.6323 18.242 22.6323 17.345C22.6323 16.487 22.3226 15.733 21.6903 15.109C21.0581 14.472 20.2968 14.16 19.4194 14.16H6.34839V10.468C6.34839 9.402 6.70968 8.518 7.44516 7.777C8.18065 7.049 9.07097 6.685 10.129 6.685H24.8387C25.729 6.685 26.4903 6.373 27.1226 5.736C27.7548 5.099 28.0645 4.345 28.0645 3.448C28.0645 2.577 27.7419 1.836 27.1226 1.199C26.4903 0.562 25.729 0.25 24.8387 0.25Z'
                fill='url(#paint0_linear_1003:654)'
            />
            <path
                d='M55.5354 0.25C54.6451 0.25 53.8967 0.562 53.2902 1.173C52.6709 1.797 52.3741 2.551 52.3741 3.435V22.558C52.3741 23.559 52.0128 24.456 51.2773 25.21C50.5547 25.977 49.6515 26.354 48.6064 26.354H40.7612C39.7031 26.354 38.8128 25.99 38.0902 25.249C37.3676 24.521 36.9934 23.611 36.9934 22.545V3.448C36.9934 2.551 36.6838 1.797 36.0515 1.186C35.4193 0.562 34.6838 0.263 33.8192 0.263C32.9289 0.263 32.1805 0.575 31.5741 1.186C30.9547 1.81 30.658 2.564 30.658 3.448V22.571C30.658 25.392 31.6386 27.797 33.6128 29.773C35.587 31.762 37.9612 32.75 40.7612 32.75H48.6064C51.4064 32.75 53.7934 31.762 55.7547 29.773C57.7289 27.784 58.7096 25.379 58.7096 22.571V3.448C58.7096 2.551 58.3999 1.797 57.7935 1.186C57.1741 0.562 56.4257 0.25 55.5354 0.25Z'
                fill='url(#paint1_linear_1003:654)'
            />
            <path
                d='M86.129 0.25H71.4193C68.6193 0.25 66.2322 1.238 64.258 3.227C62.2838 5.216 61.2903 7.621 61.2903 10.429V12.808V29.552C61.2903 30.449 61.6 31.203 62.2193 31.814C62.8387 32.438 63.5871 32.737 64.4774 32.737C65.3677 32.737 66.1161 32.425 66.7354 31.814C67.3548 31.19 67.6516 30.436 67.6516 29.552V20.582H80.6967C81.5871 20.582 82.3354 20.27 82.9677 19.633C83.6 18.996 83.9096 18.242 83.9096 17.345C83.9096 16.487 83.6 15.733 82.9677 15.109C82.3354 14.472 81.5742 14.16 80.6967 14.16H67.6387V10.468C67.6387 9.402 68 8.518 68.7354 7.777C69.4709 7.049 70.3613 6.685 71.4193 6.685H86.129C87.0193 6.685 87.7806 6.373 88.4129 5.736C89.0451 5.099 89.3548 4.345 89.3548 3.448C89.3548 2.577 89.0322 1.836 88.4129 1.199C87.7806 0.562 87.0193 0.25 86.129 0.25Z'
                fill='url(#paint2_linear_1003:654)'
            />
            <path
                d='M119.084 1.173C118.464 0.549 117.716 0.25 116.839 0.25C115.948 0.25 115.2 0.562 114.593 1.173C113.974 1.797 113.677 2.551 113.677 3.435V22.558C113.677 23.559 113.316 24.456 112.581 25.21C111.858 25.977 110.955 26.354 109.91 26.354H102.064C101.006 26.354 100.116 25.99 99.3934 25.249C98.6709 24.521 98.2967 23.611 98.2967 22.545V3.448C98.2967 2.551 97.987 1.797 97.3547 1.186C96.7225 0.562 95.987 0.263 95.1225 0.263C94.2321 0.263 93.4838 0.575 92.8773 1.186C92.258 1.81 91.9612 2.564 91.9612 3.448V22.571C91.9612 25.392 92.9418 27.797 94.916 29.773C96.8902 31.762 99.2644 32.75 102.064 32.75H109.91C112.71 32.75 115.097 31.762 117.058 29.773C119.032 27.784 120.013 25.379 120.013 22.571V3.448C120 2.551 119.69 1.797 119.084 1.173Z'
                fill='url(#paint3_linear_1003:654)'
            />
            <defs>
                <linearGradient
                    id='paint0_linear_1003:654'
                    x1='14.0323'
                    y1='0.25'
                    x2='14.0323'
                    y2='32.75'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_1003:654'
                    x1='44.6773'
                    y1='0.25'
                    x2='44.6773'
                    y2='32.75'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint2_linear_1003:654'
                    x1='75.3225'
                    y1='0.25'
                    x2='75.3225'
                    y2='32.75'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
                <linearGradient
                    id='paint3_linear_1003:654'
                    x1='105.968'
                    y1='0.25'
                    x2='105.968'
                    y2='32.75'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#7243AE' />
                    <stop offset='0.5' stopColor='#C953C1' />
                    <stop offset='1' stopColor='#F1A142' />
                </linearGradient>
            </defs>
        </svg>
    </>
);

export default Logo;
