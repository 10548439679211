const TriangleNine = () => (
    <svg
        width='296'
        height='406'
        viewBox='0 0 296 406'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        id='triangle-nine'
    >
        <path
            opacity='0.8'
            d='M17.7362 232.93C-5.24541 219.628 -5.24538 186.372 17.7362 173.07L307.793 5.18216C330.772 -8.11835 359.5 8.50604 359.5 35.1122V370.888C359.5 397.494 330.772 414.118 307.793 400.818L17.7362 232.93Z'
            stroke='#D0CFCF'
        />
    </svg>
);

export default TriangleNine;
