import { Contract } from '@ethersproject/contracts';
import { formatUnits } from '@ethersproject/units';
import { useWeb3React } from '@web3-react/core';
import React, { useContext, useState } from 'react';
import useSWR from 'swr';
import Prompt from '../components/modals/Prompt';
import GradientBorderButton from '../components/reusable/GradientBorderButton';
import config from '../config/config';
import { ETH } from '../config/constants';
import walletContext from '../context/wallet/walletContext';
import { fetcher } from '../web3/utils';

const Withdraw = () => {
    const [open, setOpen] = useState(false);

    const { selectedSymbol, openModal, closeModal, setModalMessage } =
        useContext(walletContext);

    const { chainId, library, account } = useWeb3React();

    const poolID = selectedSymbol === ETH ? config.poolIds.pair1 : config.poolIds.pair2;

    const { data: stakedLP } = useSWR(
        [config.miningAddress[chainId], 'userInfo', poolID, account],
        {
            fetcher: fetcher(library, config.miningAbi),
        }
    );

    const withdraw = async () => {
        closeWithdrawModal();
        openModal('confirmation');
        const contract = new Contract(
            config.miningAddress[chainId],
            config.miningAbi,
            library.getSigner()
        );

        try {
            const withdraw = await contract.withdraw(poolID, stakedLP.amount);
            setModalMessage(withdraw.hash);
            openModal('transactionSuccess');
        } catch (error) {
            console.log(error);
        } finally {
            closeModal('confirmation');
        }
    };

    const closeWithdrawModal = () => {
        setOpen(false);
    };

    return (
        <div className='withdraw-container'>
            <p>
                Staked Balance:
                <span className='staked'>
                    <span className='amount'>
                        {stakedLP
                            ? parseFloat(formatUnits(stakedLP.amount, 18)).toFixed(4)
                            : '0.00'}
                    </span>
                    LP
                </span>
            </p>

            <GradientBorderButton
                buttonComponent={<button type='button' />}
                buttonText='Withdraw'
                onClick={() => setOpen(true)}
            />
            <Prompt
                ariaHideApp={false}
                isOpen={open}
                title={`When you withdraw, you also harvest the rewards.`}
                onRequestClose={closeWithdrawModal}
                onConfirm={withdraw}
            />
        </div>
    );
};

export default Withdraw;
