import React from 'react';

const PooledTokens = ({ amount }) => {
    return (
        <div className='pooled-token-container'>
            <div className='row'>
                <div className='token-amount'>
                    {amount.token1Current
                        ? amount.token1Current.toSignificant(6)
                        : '0.00'}
                </div>
                <div className='token-name'>
                    {amount.token1 ? amount.token1.token.symbol : ''}
                </div>
            </div>
            <div className='row'>
                <div className='token-amount'>
                    {amount.token2Current
                        ? amount.token2Current.toSignificant(6)
                        : '0.00'}
                </div>
                <div className='token-name'>
                    {amount.token2 ? amount.token2.token.symbol : ''}
                </div>
            </div>
        </div>
    );
};

export default PooledTokens;
